import React, { Component } from "react"
import Helmet from "react-helmet"

export default class MailChimpForm extends Component {
  render() {
    return (
      <Helmet>
        <script
          type="text/javascript"
          src="//downloads.mailchimp.com/js/signup-forms/popup/unique-methods/embed.js"
          data-dojo-config="usePlainJson: true, isDebug: false"
        />
        <script type="text/javascript" src="mailchimp.js" />
        <script id="mcjs">{`!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/351340868c4bf8551ab7ae541/2dd4f38469a64f8e76bb46012.js");`}</script>
      </Helmet>
    )
  }
}
