module.exports = {
  // App Info
  appstore_link         : null, //"https://imedadel.me",                                  // Enter App Store URL.
  playstore_link        : null, //"https://imedadel.me",                                  // Enter Google Play Store URL.
  google_analytics_ID   : "UA-139557159-1",                                        // Enter Google Analytics ID or ""
  segment_prod_key      : "wNLpmfCXPgYns0RCYswQyrJJw7rnfGrZ",
  presskit_download_link: null, //"https://imedadel.me",                                  // Enter a link to downloadable file or (e.g. public Dropbox link to a .zip file). 
  video_or_screenshot   : "screenshot",                                           // "screenshot" or "video"
  app_url               : "https://piropeo.kigox.com", //"https://agalp.imedadel.me",                            // Domain of your website without path_prefix.
  path_prefix           : "/",                                                    // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name              : "Piropeo",
  app_price             : "", 
  app_description       : "Sabes que hay alguien esperando por ti.",
  app_keywords          : ["piropeo", "app", "landing page"],

  // Personal Info
  your_name              : "Piropeo, Inc.",
  your_link              : "https://piropeo.com",
  your_city              : null,
  email_address          : "info@piropeo.com",
  facebook_username      : "piropeo",
  instagram_username     : "piropeo_com",
  twitter_username       : "piropeo_com",
  github_username        : null,
  youtube_username       : null,


  // Piropos
  piropos: [
    "Si cocinas como caminas me como hasta la raspita.",
    "Mami, si San Lázaro te ve, suelta las muletas y sale corriendo.",
    "Verdad que el perfume bueno viene en frasco chiquito.",
    "Niña, si la policía te coge te pone una multa por exceso de carne en el maletero.",
    "Cariño camina por la sombrita que en el sol se derriten los bombones.",
    "¿Crees en el amor a primera vista o tengo que pasar de nuevo?",
    "Papi estás como el morro, viejo pero interesante.",
    "Dios te guarde y me dé la llave.",
    "Señora vaya con Dios, que yo voy con su hija.",
    "Si la belleza fuera pecado usted jamás iría al cielo.",
  ],

  // Features List
  features               : [
    // {
    //   title: "GitHub Pages GatsbyJS Starter",
    //   description:
    //     "Designed for GitHub Pages and Netlify. Fork. Edit site-config.js. Upload screenshot/video. Push to gh-pages branch. Voilà!",
    //   fontawesome_icon_name: "magic",
    // },
    // {
    //   title: "iPhone Device Preview",
    //   description:
    //     "Preview your app in the context of an iPhone device. Five different device colors included.",
    //   fontawesome_icon_name: "mobile",
    // },
    // {
    //   title: "Video Support",
    //   description:
    //     "Preview app video on the iPhone device simply by placing your video files in the videos folder.",
    //   fontawesome_icon_name: "play-circle",
    // },
    // {
    //   title: "Optimized Performance",
    //   description:
    //     "No need to worry about page performance or optimizing images and videos. Everything is already configured for the best performance possible!",
    //   fontawesome_icon_name: "sync",
    // },
    // {
    //   title: "Easy to Tweak",
    //   description:
    //     "Tweak accent color, images, icons and transparency via the site-config.js file. No HTML/CSS needed.",
    //   fontawesome_icon_name: "adjust",
    // },
    // {
    //   title: "Feature List",
    //   description:
    //     "Add features (like this one) to your site via the site-config.js file. No HTML/CSS needed.",
    //   fontawesome_icon_name: "star",
    // },
    // {
    //   title: "Deploy to Netlify",
    //   description: "Easily deploy your GatsbyJS landing page to Netlify.",
    //   fontawesome_icon_name: "arrow-alt-circle-down",
    // },
    // {
    //   title: "Social Links",
    //   description:
    //     "Easily add social media accounts and contact info in the footer via the site-config.js file. No HTML/CSS needed.",
    //   fontawesome_icon_name: "link",
    // },
    // {
    //   title: "FontAwesome Support",
    //   description:
    //     "Pick custom Font Awesome icons for the feature list via the site-config.js file. No HTML/CSS needed.",
    //   fontawesome_icon_name: "info-circle",
    // },
  ],
  header_background             : "rgba(0, 0, 0, 0.1)",
  topbar_title_color            : "#ffffff",
  cover_overlay_color_rgba      : "rgba(54, 59, 61, 0.8)",
  device_color                  : "blue",                          // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color         : "#ffffff",
  primary_text_color            : "#000",
  content_width                 : "1170px",
  font                          : `"Helvetica Neue", sans-serif`,
  link_color                    : "#1d63ea",
  app_title_color               : "#ffffff",
  app_price_color               : "#ffffff",
  app_description_color         : "#ffffff",
  feature_title_color           : "#000000",
  feature_text_color            : "#666666",
  feature_icons_foreground_color: "#1d63ea",
  feature_icons_background_color: "#e6e6e6",
  social_icons_foreground_color : "#666666",
  social_icons_background_color : "#e6e6e6",
  footer_text_color             : "#666666",
}
